.everything-else2__litepaper-module {
	/*background: rgba(0, 0, 0, 0.2);*/
	background: transparent;
	/*border-radius: 10px;*/
	padding: 25px;
	/*margin-bottom: 10px;*/
	min-height: 150px;
	text-align: center;
}

.everything-else2__litepaper-module-title {
	/*color: #f15928;*/
	/*color: #515569;*/
	color: #009aff;
	width: 80%;
	margin: auto auto;
	font-weight: 900;
	font-size: 22.5px;
	margin-bottom: 5px;
	text-align: left;
}

.everything-else2__litepaper-module-text {
	width: 90%;
	margin: auto auto;
	/*position: relative;*/
	/*top: 50%;*/
	/*transform: translate(0, -50%);*/
	font-size: 14px;
	text-align: left;
}

.everything-else2__litepaper-module-img {
	width: 80px;
	height: 80px;
	margin: 10px;
}