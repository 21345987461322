html, body {
     background: #151417;
     position: fixed;
     width: 100%!important;
     min-height:100%;
     height:100%;
     overflow-x:hidden;
     display:block;
}

html {
    min-height: 100%;
}
body {
    min-height: 100%;
}

.App {
  text-align: center;
  background: #151417;
}