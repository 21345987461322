/*.navbar-prefix {
	height: 10px;
	background: #363B4C;
	border-bottom: solid 10px black;
}*/

.navbar-custom {
	padding-left: 20px;
	padding-right: 20px;
	background: transparent!important;
	/*box-shadow: 0px 0.5px 3px rgb(255, 255, 255, 0.5);*/
	font-family: Montserrat;
	position: fixed;
	/*top: 0;*/
}

.navbar-custom__link {
	border-radius: 20px;
	background: #353740;
	/*background: transparent;*/
	/*background: #bcdcf4;*/
	/*background: #515569;*/
	/*border: solid white 2px;*/
	/*border: solid white 2px;*/
	border-bottom-width: 3px;
	width: 140px;
	margin: 10px;
	color: white!important;
	/*color: white!important;*/
	margin-left: auto!important;
	padding-top: 10px;
	padding-bottom: 10px;
	text-decoration: none;
}

.navbar-custom__home {
	color: white!important;
}

.logo {
	width: 60px;
	height: 60px;
}

.navbar {
	width: 100%;
	z-index: 3;
}

.navbar-light .navbar-toggler {
/*	border: solid 2px white;
	color: white;*/
	border: none;
}

.navbar-toggler-icon {
    right: 30px;
    position: absolute;
    top: 30px;
}

.navbar-toggler:focus {
	box-shadow: none;
}

.no-border {
	border: none!important;
	border-bottom: none!important;
	background: none!important;
}












.toggle-btn {
	display: none;
}

.visible {
	display: block!important;
}

.close-btn {
	color: #009aff!important;
	margin: 30px auto!important;
	border: none!important;
	font-size: 25px!important;
	margin: 0 auto;
	display: none;
}

/*@keyframes fade {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes unfade {
  from {
    opacity: 1;
    visibility: hidden;
    transform: translateY(10px);
  }
  to { 
    opacity: 0;
    visibility: visible;
    transform: translateY(0);
  }
}*/

/*.dropdown {
   animation: fade 2s linear forwards;
}*/

/*.fade-in {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}*/


/* #### Mobile Phones Portrait #### */
@media screen and (max-device-width: 480px) and (orientation: portrait){
	.mr-auto.open {
		position: absolute;
		top: -10px;
		left: 0;
		height: 1000px;
		width: 100%;
		background: #151417;
		/*animation: fade 0.2s linear forwards;*/
	}

	.navbar-custom__link {
		display: none;
		width: 80%!important;
		margin: 10px auto;
		border-radius: 20px;
/*		border: solid 1px #009aff!important;
		border-bottom: solid 3px #009aff!important;*/
		font-size: 25px;
		/*background: #353740!important;*/
		background: transparent;
		color: white!important;
	}

	.toggle-btn {
		position: absolute;
		top: 20px;
		right: 20px;
		display: block;
	}

	.close-btn-container {
	  display: inline-block;
	  cursor: pointer;
	}

	.close-bar1, .close-bar2 {
	  width: 35px;
	  height: 5px;
	  background-color: #009aff;
	  margin: 17.5px 0;
	}

	.close-bar1 {
	  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
	  transform: rotate(-45deg) translate(-8px, 8px);
	}

	.close-bar2 {
	  -webkit-transform: rotate(45deg) translate(-8px, -8px);
	  transform: rotate(45deg) translate(-8px, -8px);
	}

	.no-border {
		border: none!important;
		border-bottom: none!important;
		background: none!important;
	}
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape){
	.mr-auto.open {
		position: absolute;
		top: -10px;
		left: 0;
		height: 1000px;
		width: 100%;
		background: #151417;
	}
	.navbar-custom__link {
		display: none;
		width: 100%;
		border-radius: 20px;
/*		border: solid 1px #009aff!important;
		border-bottom: solid 3px #009aff!important;*/
		font-size: 25px;
		background: transparent;
		color: white!important;
	}

	.toggle-btn {
		position: absolute;
		top: 20px;
		right: 20px;
		display: block;
	}

	.close-btn-container {
	  display: inline-block;
	  cursor: pointer;
	}

	.close-bar1, .close-bar2 {
	  width: 35px;
	  height: 5px;
	  background-color: #009aff;
	  margin: 17.5px 0;
	}

	.close-bar1 {
	  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
	  transform: rotate(-45deg) translate(-8px, 8px);
	}

	.close-bar2 {
	  -webkit-transform: rotate(45deg) translate(-8px, -8px);
	  transform: rotate(45deg) translate(-8px, -8px);
	}

	.no-border {
		border: none!important;
		border-bottom: none!important;
		background: none!important;
	}
}

/* #### Mobile Phones Portrait or Landscape #### */
@media screen and (max-device-width: 640px){
	.mr-auto.open {
		position: absolute;
		top: -10px;
		left: 0;
		height: 1000px;
		width: 100%;
		background: #151417;
	}

	.navbar-custom__link {
		display: none;
		width: 100%;
		border-radius: 20px;
/*		border: solid 1px #009aff!important;
		border-bottom: solid 3px #009aff!important;*/
		font-size: 25px;
		background: transparent;
		color: white!important;
	}

	.toggle-btn {
		position: absolute;
		top: 20px;
		right: 20px;
		display: block;
	}

	.close-btn-container {
	  display: inline-block;
	  cursor: pointer;
	}

	.close-bar1, .close-bar2 {
	  width: 35px;
	  height: 5px;
	  background-color: #009aff;
	  margin: 17.5px 0;
	}

	.close-bar1 {
	  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
	  transform: rotate(-45deg) translate(-8px, 8px);
	}

	.close-bar2 {
	  -webkit-transform: rotate(45deg) translate(-8px, -8px);
	  transform: rotate(45deg) translate(-8px, -8px);
	}

	.no-border {
		border: none!important;
		border-bottom: none!important;
		background: none!important;
	}
}

/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
	.mr-auto.open {
		position: absolute;
		top: -10px;
		left: 0;
		height: 1000px;
		width: 100%;
		background: #151417;
	}

	.navbar-custom__link {
		display: none;
		width: 100%;
		border-radius: 20px;
/*		border: solid 1px #009aff!important;
		border-bottom: solid 3px #009aff!important;*/
		font-size: 25px;
		background: transparent;
		color: white!important;
	}

	.toggle-btn {
		position: absolute;
		top: 20px;
		right: 20px;
		display: block;
	}

	.close-btn-container {
	  display: inline-block;
	  cursor: pointer;
	}

	.close-bar1, .close-bar2 {
	  width: 35px;
	  height: 5px;
	  background-color: #009aff;
	  margin: 17.5px 0;
	}

	.close-bar1 {
	  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
	  transform: rotate(-45deg) translate(-8px, 8px);
	}

	.close-bar2 {
	  -webkit-transform: rotate(45deg) translate(-8px, -8px);
	  transform: rotate(45deg) translate(-8px, -8px);
	}

	.no-border {
		border: none!important;
		border-bottom: none!important;
		background: none!important;
	}
}

/* #### Desktops #### */
@media screen and (min-width: 1024px){
  /* some CSS here */
}




/*
.navbar-collapse.collapse {
	background: purple;
	padding: 0px!important;
}

.navbar-collapse.collapsing {
	background: purple;
	padding: 0px!important;
}
*/









.toggle-btn-container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
  transform: rotate(-45deg) translate(-8px, 8px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}