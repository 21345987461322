.everything-else2 {
  position: absolute;
  top: 80px;
  width: 100%;
  /*min-height: 80%;*/
  height: 0px;
  /*background: rgba(51, 51, 51, 0.75);*/
  /*background: #383434;*/
  color: white;
  padding-bottom: 10px;
  padding-top: 20px;
  z-index: 1;
}

.everything-else2__content {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 20px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.bullet {
  font-size: 14px;
  color: #009aff;
}

.title {
  color: #009aff;
  width: 80%;
  margin: auto auto;
  font-weight: 900;
  font-size: 35px;
  margin-bottom: 5px;
  padding-top: 25px;
  text-align: center;
  font-weight: 700;
}

.text {
  width: 90%;
  margin: auto auto;
  /*position: relative;*/
  /*top: 50%;*/
  /*transform: translate(0, -50%);*/
  font-size: 14px;
  text-align: left;
  padding: 25px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* #### Mobile Phones Portrait #### */
@media screen and (max-device-width: 480px) and (orientation: portrait){
  .everything-else2__content {
    /*position: relative;*/
    /*top: 20px;*/
    width: 95%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape){
  .everything-else2__content {
    /*position: relative;*/
    /*top: 20px;*/
    width: 95%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

/* #### Mobile Phones Portrait or Landscape #### */
@media screen and (max-device-width: 640px){
  .everything-else2__content {
    /*position: relative;*/
    /*top: 20px;*/
    width: 95%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
  .everything-else2__content {
    /*position: relative;*/
    /*top: 20px;*/
    width: 95%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

/* #### Desktops #### */
@media screen and (min-width: 1024px){
  /* some CSS here */
}