.everything-else1 {
  position: absolute;
  top: 100%;
  height: 20%;
  width: 100%;
  background: /*#353740*/;
  background: #515569;
  /*background: lightblue;*/
  z-index: 1;
}

.logo-img {
	height: 35px;
	margin-left: 17.5px;
	margin-right: 17.5px;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}



/* #### Mobile Phones Portrait #### */
@media screen and (max-device-width: 480px) and (orientation: portrait){
	.logo-img {
		height: 17.5px;
		margin-left: 8.75px;
		margin-right: 8.75px;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape){
  /* some CSS here */
}

/* #### Mobile Phones Portrait or Landscape #### */
@media screen and (max-device-width: 640px){
  /* some CSS here */
}

/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
  /* some CSS here */
}

/* #### Desktops #### */
@media screen and (min-width: 1024px){
  /* some CSS here */
}